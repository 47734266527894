// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@import "email/email-custom";
@import "modules/footer";

.social-register > .text-center > a.primary.external-link-container {color: #002057;}

.social-register > .text-center a.button--france_connect > img {
  height: 60px;
  width: 228px;
}
.social-register > .text-center a.button--france_connect > img:hover {
  filter: brightness(165%);
}

a.button--france_connect {
  display: block;
}

a[title="Find out more about cookies"]{
  color: #000000;
  text-decoration: underline;
}
